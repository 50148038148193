<template>

    <div>

        <!-- Filters -->
        <provider-forms-list-filters
			:options="options"
			:states-filter.sync="statesFilter"
            :conditions-filter.sync="conditionsFilter"
            :start-date-range-picker-filter.sync="startDateRangePickerFilter"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="4"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                </b-row>

            </div>
            
            <b-table
                ref="refFormsListTable"
                class="position-relative text-center"
                :style="'height: inherit'"
                :items="fetchForms"
                responsive
                :fields="tableColumns"
                primary-key="id2"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

                <template #head(start_date)="data">
					<span v-html="data.label"></span>
				</template>

                <template #head(email_sent_count)="data">
					<span v-html="data.label"></span>
				</template>

                <template #cell(start_date)="data">
                    <b-link
                        v-if="!data.item.locked"
                        :to="{ name: 'forms-edit', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap"
                    >   
                        <b-badge variant="light-info">
                            {{ data.item.month }}
                        </b-badge>
                    </b-link>
                    <b-badge
                        v-else
                        variant="light-secondary"
                    >   
                        <feather-icon icon="LockIcon" />
                        {{ data.item.month }}
                    </b-badge>
				</template>

                <template #cell(state_name)="data">
                    <b-badge :variant="data.item.state_color">{{ data.item.state_name }}</b-badge>
				</template>

                <template #cell(condition_name)="data">
                    <b-badge :variant="data.item.condition_color">{{ data.item.condition_name }}</b-badge>
				</template>

                <template #cell(createdAt)="data">
                    {{ moment(data.item.createdAt).format('DD/MM/YYYY') }}
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item 
                            v-if="data.item.report_path"
                            @click=viewGuaranteeReport(data.item.id)
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar informe fondo de garantía
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.report_path"
                            @click="openModalSendEmailToSeveral(data.item)"
                        >
                            <feather-icon icon="SendIcon" />
                            <span class="align-middle ml-50">Mandar informe</span>
                        </b-dropdown-item>

                        <b-dropdown-divider v-if="data.item.report_path" />

                        <b-dropdown-item
                            v-if="!data.item.locked"
                            :to="{ name: 'forms-edit', params: { id: data.item.id } }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Modificar data</span>
                        </b-dropdown-item> 

                        <b-dropdown-item
                            v-if="!data.item.locked && userData.role === 'superadmin'"
                            @click="lockedForm(data.item.id)"
                        >
                            <feather-icon icon="LockIcon" />
                            <span class="align-middle ml-50">Bloquear</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.locked && userData.role === 'superadmin'"
                            @click="unlockedForm(data.item.id)"
                        >
                            <feather-icon icon="UnlockIcon" />
                            <span class="align-middle ml-50">Desbloquear</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteForm(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalForms"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>

        <BButtonSendEmailToSeveral 
			ref="sendEmailToSeveral" 
			:data="data"
			@refresh-data="refetchData"
		/>

    </div>

</template>

<script>

    import BButtonSendEmailToSeveral from '@core/components/b-button-send-email-to-several/BButtonSendEmailToSeveral.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import ProviderFormsListFilters from './ProviderFormsListFilters.vue';
    import providerFormStoreModule from './providerFormStoreModule';
    import { useToast } from 'vue-toastification/composition';
    import useProviderFormsList from './useProviderFormsList';
    import VueSweetalert2 from 'vue-sweetalert2';
    import Ripple from 'vue-ripple-directive';
    import { v4 as uuidv4 } from 'uuid';
    import vSelect from 'vue-select';
    import moment from 'moment';
    import store from '@/store';
    import axios from '@axios';
    import Vue from 'vue';
	import 'animate.css';
	
	Vue.use(VueSweetalert2);

    export default {
        components: {
            BButtonSendEmailToSeveral,
            ProviderFormsListFilters,

            vSelect
        },
        props: {
            providerData: {
                type: Object,
                required: true
            }
        },
        directives: {
            Ripple
        },
        data (){
            return {
                userData: JSON.parse(localStorage.getItem('userData')),
                moment
            }
        },
		setup(props) {

			// USE TOAST
			const toast = useToast();

            // REFS
			const providerId = props.providerData.id;
            const sendEmailToSeveral = ref(null); 
            const options = ref({});
            const data = ref({});

			const PROVIDER_FORM_APP_STORE_MODULE_NAME = 'app-provider-form';

			// REGISTER MODULE
			if (!store.hasModule(PROVIDER_FORM_APP_STORE_MODULE_NAME)) store.registerModule(PROVIDER_FORM_APP_STORE_MODULE_NAME, providerFormStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(PROVIDER_FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVIDER_FORM_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/forms/filters');
                options.value = data.data;
            };

            const openModalSendEmailToSeveral = (object) => {
				data.value = {
                    code: uuidv4(),
					form_id: object.id,
					title: 'MANDAR INFORME',
                    mode: 'sendReport',
                    options: {
                        checkers: options.value.checkers
                    }
				}

				sendEmailToSeveral.value.modal.show();
			};

            const viewGuaranteeReport = (id) => {

                store.dispatch('app-provider-form/viewGuaranteeReport', { id })
                    .then( response => {

                        window.open(response.data.url, '_blank');

                    })
                    .catch( () => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error al descargar el informe de fondo de garantía.',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    });

            };

            const lockedForm = (id) => {
		
                Vue.swal({
                    title: '¿Está seguro(a) de bloquear el formulario?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, bloquear!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        store.dispatch('app-provider-form/lockedForm', { id })
                            .then( response => {

                                refetchData();

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                            })
                            .catch( () => {

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al bloquear el formulario',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            });
                    }

                });

            };

            const unlockedForm = (id) => {
		
                Vue.swal({
                    title: '¿Está seguro(a) de desbloquear el formulario?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, desbloquear!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        store.dispatch('app-provider-form/unlockedForm', { id })
                            .then( response => {

                                refetchData();

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                            })
                            .catch( () => {

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al desbloquear el formulario',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            });
                    }

                });

            };
		
			const deleteForm = (id) => {
		
				Vue.swal({
					title: '¿Está seguro(a) de eliminar el formulario?',
					text: "¡Si no lo está, puede cancelar la acción!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Sí, eliminar!',
					cancelButtonText: 'Cancelar',
					customClass: {
						confirmButton: 'btn btn-primary',
						cancelButton: 'btn btn-outline-danger ml-1'
					},
					showClass: {
						popup: 'animate__animated animate__tada'
					},
					buttonsStyling: false
				}).then(result => {

					if (result.value) 
					{
						store.dispatch('app-provider-form/deleteForm', { id })
							.then( response => {

								refetchData();

								toast({
									component: ToastificationContent,
									props: {
										title: response.data.message,
										icon: 'CheckIcon',
										variant: 'success'
									}
								});

							})
							.catch( () => {

								toast({
									component: ToastificationContent,
									props: {
										title: 'Error al eliminar el formulario',
										icon: 'AlertTriangleIcon',
										variant: 'danger'
									}
								});

							});
					}

				});

			};
			
			const {
				fetchForms,
				tableColumns,
				perPage,
				currentPage,
				totalForms,
				dataMeta,
				perPageOptions,
				sortBy,
				isSortDirDesc,
				refFormsListTable,
				refetchData,

				// EXTRA FILTERS
                statesFilter,
                conditionsFilter,
                startDateRangePickerFilter
			} = useProviderFormsList(providerId);
			
			return {
				fetchForms,
				tableColumns,
                providerId,
                options,
				perPage,
				currentPage,
				totalForms,
				dataMeta,
				perPageOptions,
				sortBy,
				isSortDirDesc,
				refFormsListTable,
				refetchData,

                // REFS
                sendEmailToSeveral,
                data,

				// EXTRA FILTERS
                statesFilter,
                conditionsFilter,
                startDateRangePickerFilter,
				
				// ACTIONS
                openModalSendEmailToSeveral,
                viewGuaranteeReport,
                lockedForm,
                unlockedForm,
				deleteForm
			}
		}
    }

</script>

<style lang="scss" scoped>

	.per-page-selector {
		width: 90px;
	}    

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>