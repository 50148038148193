<template>
    <b-card no-body>
        <b-card-header>
            <h5 class="mb-0 text-white"><b>FILTROS</b></h5>
        </b-card-header>
        <b-card-body class="pt-2">
            <b-row>

                <b-col
                    cols="12"
                    md="4"
                    class="mb-2"
                >
                    <label for="states"><strong>ESTADO(S)</strong></label>
                    <v-select
                        id="states"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        v-model="formData.statesArray"
                        :options="options.states"
                        class="w-100"
                        @input="inputStatesVal()"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="4"
                    class="mb-2"
                >
                    <label for="conditions"><strong>CONDICIÓN(ES)</strong></label>
                    <v-select
                        id="conditions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        v-model="formData.conditionsArray"
                        :options="options.conditions"
                        class="w-100"
                        @input="inputConditionsVal()"
                    />
                </b-col>

                <b-col
                    cols="12"
                    md="4"
                    class="mb-2"
                >
                    <label for="startRangePicker"><strong>RANGE DE FECHAS DE LA EVALUACIÓN</strong></label>
                    <b-input-group>
                        <flat-pickr
                            id="startRangePicker"
                            @on-change="onChangeStartDate"
                            v-model="formData.startRangePicker"
                            class="form-control"
                            :config="config"
                            placeholder="YYYY-MM-DD"
                        />
                        <b-input-group-append>
                            <b-button
                                variant="primary"
                                @click="clearDate('start')"
                            >
                                <feather-icon
                                    icon="XIcon"
                                    size="12"
                                />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>

            </b-row>

        </b-card-body>
    </b-card>
</template>

<script>

    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { ref } from '@vue/composition-api';
    import vSelect from 'vue-select';

    export default {
        components: {
            flatPickr,
            vSelect
        },
        props: {
            options: {
                type: Object,
                required: true
            }
        },
        setup(props, { emit }) {

            // REFS
            const config = ref({
                wrap: true,
                mode: 'range',
                locale: Spanish
            });

            const formData = ref({
                statesArray: [],
                conditionsArray: [],

                startMinDate: null,
                startMaxDate: null,
                startRangePicker: null
            });

            const clearDate = (index) => {
                formData.value[`${index}MinDate`] = null;
                formData.value[`${index}MaxDate`] = null;
                formData.value[`${index}RangePicker`] = null;
            };

            const inputStatesVal = () => {
                if (formData.value.statesArray)
                {
                    const array = formData.value.statesArray.map(item => item.value);
                    emit('update:statesFilter', array.join(','));

                    return array;
                }

                return null;
            };

            const inputConditionsVal = () => {
                if (formData.value.conditionsArray)
                {
                    const array = formData.value.conditionsArray.map(item => item.value);
                    emit('update:conditionsFilter', array.join(','));

                    return array;
                }

                return null;
            };

            const onChangeStartDate = () => {

                if (typeof formData.value.startRangePicker === 'string' && formData.value.startRangePicker)
                {
                    let range = formData.value.startRangePicker + ' ' + formData.value.startRangePicker;

                    if (formData.value.startRangePicker.includes('a')) 
                    {
                        const rangeDates = formData.value.startRangePicker.split( 'a ');

                        formData.value.startMinDate = rangeDates[0];
                        formData.value.startMaxDate = rangeDates[1];

                        range = formData.value.startMinDate + formData.value.startMaxDate;
                    }

                    emit('update:startDateRangePickerFilter', range);

                } else {
                    emit('update:startDateRangePickerFilter', null);
                }

            };

            return {
                // DATA
                formData,
                config,

                // METHODS
                inputStatesVal,
                inputConditionsVal,
                onChangeStartDate,
                clearDate
            }
        }
    }

</script>

<style lang="scss" scoped>

    .card-header {
        background-color: var(--primary);
        padding: 1rem 1.5rem;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>