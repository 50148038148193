import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchForms(ctx, { id, queryParams }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/providers/${id}/forms`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        viewGuaranteeReport(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/forms/${id}/view-guarantee-report`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        lockedForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/forms/${id}/locked`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        unlockedForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/forms/${id}/unlocked`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/forms/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}