
<template>
    <component :is="'div'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="providerData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del proveedor</h4>
            <div class="alert-body">
                No se encontró ningún proveedor con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'providers-list'}"
                >
                    Lista de proveedores
                </b-link>
                para buscar otro proveedor.
            </div>
        </b-alert>

        <b-tabs
            v-if="providerData"
            pills
        >
      
            <!-- Tab: General Information -->
            <b-tab :active="tabGeneralInformationActive">

                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información general</span>
                </template>
                
                <provider-edit-tab-general-information class="mt-2 pt-75" :provider-data="providerData" />
                
            </b-tab>

            <!-- Tab: Forms -->
            <b-tab :active="tabFormsActive">

                <template #title>
                    <feather-icon
                        icon="ClipboardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Formularios</span>
                </template>

                <provider-edit-tab-forms class="mt-2 pt-75" :provider-data="providerData" />

            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import ProviderEditTabGeneralInformation from './general-information/ProviderEditTabGeneralInformation.vue';
    import ProviderEditTabForms from './forms/ProviderEditTabForms.vue';
    import { ref, onMounted, onUnmounted } from '@vue/composition-api';
    import providerStoreModule from '../providerStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            ProviderEditTabGeneralInformation,
            ProviderEditTabForms
        },
        setup() {

            // REFS
            const tabGeneralInformationActive = ref(true);
            const tabFormsActive = ref(false);

            const providerData = ref(null);

            const PROVIDER_APP_STORE_MODULE_NAME = 'app-provider';

            // REGISTER MODULE
            if (!store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.registerModule(PROVIDER_APP_STORE_MODULE_NAME, providerStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted( () => {
                if (store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVIDER_APP_STORE_MODULE_NAME)
            });

            onMounted( () => {

                if (router.currentRoute.query.tab && router.currentRoute.query.tab === 'forms')
                {
                    tabGeneralInformationActive.value = false;
                    tabFormsActive.value = true;
                }

            });

            store.dispatch('app-provider/fetchProvider', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        providerData.value = undefined
                    } else {
                        providerData.value = response.data.provider;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        providerData.value = undefined
                    }
                });

            return {
                // DATA
                tabGeneralInformationActive,
                tabFormsActive,
                
                providerData
            }
        }
    }

</script>